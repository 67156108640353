import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
import axios from "axios";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";

//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';




const BasicDatatable = () => {
  const { t } = useTranslation();
  const { CurrencySymbol } = GlobalSet();
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

 /*.....income data......*/
 const [income, setIncome] = useState([]);
    useEffect(()=>{
      const selectedCurrency = localStorage.getItem('selectedCurrency');
      const auth_token = localStorage.getItem('auth_token');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/ticket',data)
         .then(response=>{
           setIncome(response.data.data)
           console.log(response.data.data)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }

    },[])





  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">


              <Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>Support Ticket</Card.Title>
                    <Card.Title><span> 
                      <Link to="/create-ticket"><Badge variant="success light">Create Ticket</Badge></Link></span></Card.Title>
                   </Card.Header>
                  <Card.Body>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Ticket ID</strong>
                          </th>
                          <th>
                            <strong>Subject</strong>
                          </th>
                          <th>
                            <strong>Status</strong>
                          </th>
                          <th>
                            <strong>Date</strong>
                          </th>
                          <th>
                            <strong>Date</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                        {income.map((incomerow)=>(
                          <tr  key={incomerow.id}>
                            <td>{incomerow.ticket}</td>
                            <td>{incomerow.subject}</td>
                            <td>
                               {
                                incomerow.status===1 ?
                                   <span class="badge bg-secondary">Active</span>
                                   :  
                                   <span class="badge bg-primary">Solved</span>

                               } 
                            </td>
                            <td>
                                <Dropdown.Toggle
                                  variant="success"
                                  className="light sharp i-false"
                                >
                                 {incomerow.created_at}
                                </Dropdown.Toggle>
                            </td>
                            <td>



                              <Link key={incomerow.id} to={"view-ticket/"+incomerow.ticket}>
                                <button type="button" class="me-2 btn btn-warning">View</button>
                             </Link>
                            </td>
                          </tr>


                          ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>


        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
