import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import profile from "../../../images/logo.png";
import logo from "../../../images/logomob.png";


const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
			<Fragment>
  		<img style={{ width:"80%" }} className="form-inline d-none d-lg-block d-xl-block" src={profile} alt=""/>
      <img style={{ width:"70px" }} className="form-inline d-xl-none d-lg-none" src={logo} alt=""/>
			</Fragment>
        ) : (
			<Fragment>
      <img style={{ width:"80%" }} className="form-inline d-none d-lg-block d-xl-block" src={profile} alt=""/>
      <img style={{ width:"70px" }} className="form-inline d-xl-none d-lg-none" src={logo} alt=""/>
			</Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
