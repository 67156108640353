import React, { Fragment,useState,useEffect } from "react";
import { Form,  Card,  Badge,} from "react-bootstrap";
import { Link, useHistory , useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import Select from "react-select";
import axios from "axios";
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Checkout = () => {

  const { id } = useParams();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();
  const [options, setoptions] = useState([]);

  const loadViewTicketData = () => {
      const data = {
        id: id,
        key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
      axios.post('/view-ticket',data)
      .then(response=>{
         setoptions(response.data.data)
     })
   .catch(error=>{
      console.log(error)
     })
  };
  useEffect(() => {
      loadViewTicketData();
  }, []);

  const handleReloadData = () => {
      loadViewTicketData();
  };


    const [depositInput, setDepositInput] = useState({
        message: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }

    const depositSubmit = (e) => {
      e.preventDefault();
      const auth_token = localStorage.getItem('auth_token');
      const data = {
        message: depositInput.message,
        ticket_id: id,
        key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
         
      }
      if (auth_token !== null) {
        axios.post(`/ticket-submit`, data).then(res => {
          if(res.data.status === 'success')
          { 
           loadViewTicketData();
             swal("Success",res.data.message,"success");
            setDepositInput({...depositInput,
              message: '',
              
            });
          }
          else if(res.data.status === 'fail')
          {
            swal("Warning",res.data.message,"warning");
          }
          else 
          {
          setDepositInput({...depositInput, error_list: res.data.validation_error});
          }
        });
       }
    }
   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-12">
                          <Card.Header>
                            <Card.Title>Support Ticket</Card.Title>
                            <Card.Title><span> 
                              <Link to="/support-ticket"><Badge variant="success light">All Ticket</Badge></Link></span></Card.Title>
                           </Card.Header>
                          <div className="card-body">

                            <div>
                               <div className="row">
                                {options.map((ele)=>(
                                  ele.admin_id=== null ?
                                    <div className="col-xl-12 col-sm-12 col-12 mb-12 col-xxl-12">
                                      <div style={{ float: "right"}}>
                                        <h6 className="fs-20 font-w600">{ ele.message }</h6>
                                        <span className="fs-10">{ ele.created_at }</span>
                                      </div>
                                    </div>
                                     :  
                                    <div className="col-xl-12 col-sm-12 col-12 mb-12 col-xxl-12">
                                      <div style={{ float: "left"}}>
                                        <h6 className="fs-20 font-w600">{ ele.message }</h6>
                                        <span className="fs-10">{ ele.created_at }</span>
                                      </div>
                                    </div>
                                 ))}
                               </div>
                              </div>

                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">
                                 <div className="col-md-12 mb-3">
                                     <label htmlFor="lastName">Message</label>
                                      <div className="form-group mb-3">
                                      <textarea onChange={handleInput} value={depositInput.message}  required name="message" id="email-compose-editor" className="textarea_editor form-control bg-transparent" rows="8" placeholder="Enter text ..." spellcheck="false">
                                      
                                      </textarea>
                                      </div>
                                      <span className="text-danger">{depositInput.error_list.message}</span>
                                 </div>
                              </div>
                              <button
                                 style={{ float: "right"}}
                                 className="btn btn-primary btn-lg float-right"
                                 type="submit"
                              >
                                 Submit 
                              </button>
                           </form>
                          </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
